<template>
  <div class="alarms-number" ref="alarmsNumber">
    <div class="function-buttons" ref="functionButtons" v-show="false">
      <el-button type="primary" size="small"></el-button>
    </div>
    <div class="form-area" ref="form">
      <analysis-form @getFormVal="onSubmit">
        <el-button
          type="primary"
          size="small"
          :disabled="tableData.length === 0"
          @click="exportData(1)"
          :loading="downLoadStatus"
          >导出</el-button
        >
      </analysis-form>
    </div>
    <el-table id="outputTable" :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column
        prop="companyName"
        label="所属车队"
        :show-overflow-tooltip="true"
        :width="120 * screenCoefficient"
      ></el-table-column>
      <el-table-column
        prop="cph"
        label="车牌号"
        :width="100 * screenCoefficient"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="licensePlateColor"
        label="车牌颜色"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">{{
          colorObj[Number(scope.row.licensePlateColor)]
        }}</template>
      </el-table-column>
      <el-table-column
        prop="deviceNos"
        label="终端号"
        :width="100 * screenCoefficient"
        :show-overflow-tooltip="true"
      >
      </el-table-column>

      <el-table-column prop="speed" label="超速报警"></el-table-column>
      <el-table-column prop="fatigue" label="疲劳驾驶"></el-table-column>
      <el-table-column prop="smoke" label="抽烟"></el-table-column>
      <el-table-column
        prop="call"
        label="接打手机电话报警"
        :width="140 * screenCoefficient"
      ></el-table-column>
      <el-table-column
        prop="distracted"
        label="长时间不目视前方"
        :width="140 * screenCoefficient"
      ></el-table-column>
      <el-table-column
        prop="frontalCollision"
        label="前向碰撞报警"
        :width="110 * screenCoefficient"
      ></el-table-column>
      <el-table-column
        prop="trackDeviate"
        label="车道偏离报警"
        :width="110 * screenCoefficient"
      ></el-table-column>
      <el-table-column
        prop="headwayClose"
        label="车距过近报警"
        :width="110 * screenCoefficient"
      ></el-table-column>
      <el-table-column
        prop="noDetectionDriver"
        label="未检测到驾驶员"
        :width="140 * screenCoefficient"
      ></el-table-column>
      <el-table-column
        prop="detectionFunction"
        label="驾驶员检测功能失效"
        :width="150 * screenCoefficient"
      ></el-table-column>
      <el-table-column
        prop="driveAssist"
        label="驾驶员辅助功能失效报警"
        :width="180"
      ></el-table-column>
    </el-table>
    <div ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import analysisForm from "@/components/analysisForm/analysisForm.vue";
import { queryDictsByCodes } from "@/api/lib/api.js";
import { queryAlarmTypeStatistics } from "@/api/lib/gps-api.js";
import { formatDict, getCurent, formatDate } from "@/common/utils/index";
import { addExcelExport } from "@/api/lib/refreshAlarm.js";
import { mapGetters } from 'vuex'

export default {
  name: "alarmsNumber",
  components: {
    analysisForm,
  },
  data () {
    return {
      tableHeight: 0,
      tableData: [],
      state: 0,
      form: {
        vehicleNos: null,
        companyId: null,
        beginTime: null,
        endTime: null,
        currentPage: 1,
        pageSize: 10,
      },
      total: 0,
      typeObj: {},
      colorObj: {},
      exportForm: {},
      downLoadStatus: false,
    };
  },
  computed: {
    ...mapGetters({
      screenCoefficient: 'screenCoefficient'
    }),

  },
  methods: {
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.alarmsNumber.clientHeight;
      const buttonsHeight = this.$refs.functionButtons.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;

      this.tableHeight = wholeHeight - 16 - buttonsHeight - 16 - formHeight - paginationHeight - 10;
    },
    //导出
    exportData () {
      this.downLoadStatus = true;
      delete this.exportForm.currentPage;
      delete this.exportForm.pageSize;
      let data = {
        baseUrl: "report",
        userId: sessionStorage.getItem("userId"),
        createTime: formatDate(new Date()),
        fileName:
          `报警次数统计-${getCurent()}`,
        filePath: null,
        fileStatus: 1,
        generateTime: null,
        queryParam: JSON.stringify(this.exportForm),
        queryPath: `/illegalExport/alarmTypeStatisticsExport`,
      };
      addExcelExport(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.downLoadStatus = false;
          this.$store.dispatch("setRefreshStatus", true);
        })
        .catch(() => {
          this.downLoadStatus = false;
        });
    },
    // 提交
    onSubmit (value) {
      this.form = { ...this.form, ...value };
      this.form.currentPage = 1;
      this.getDataList();
    },

    // 获取列表
    getDataList () {
      let data = { ...this.form };
      queryAlarmTypeStatistics(data).then(res => {
        if (res.code === 1000) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        } else {
          this.tableData = [];
          this.total = 0;
        }
        this.exportForm = { ...data };
      })
    },

    // 当前页码改变
    onCurrentChange (page) {
      this.form.currentPage = page;
      this.getDataList();
    },
    // 当前条数改变
    onSizeChange (size) {
      this.form.pageSize = size;
      this.getDataList();
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: "VETY,CPYS" }).then((res) => {
        if (res.code === 1000) {
          this.typeObj = formatDict(res.data.VETY);
          this.colorObj = formatDict(res.data.CPYS);
        }
      });
    },
  },
  created () {
    this.getDicts();
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>

<style lang="scss" scoped>
.alarms-number {
  height: 100%;
  width: 100%;
  .el-tabs {
    height: 100%;
  }
}
.theme-project-gps {
  .alarms-number {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>
